<template>
  <div class="main-container">
    <v-toolbar
      flat
      tile
      class="box box-1"
      style="background-color: transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0"> เปิดออเดอร์ Order</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card class="mx-4 box box-2" style="min-height: 100%">
      <splitpanes>
        <pane
          :size="40"
          min-size="20"
          class="pr-2 pl-4 py-4"
          style="overflow: auto"
        >
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row justify="space-between">
              <v-col align-self="center">หมายเลข PO อ้างอิง</v-col>
              <v-col class="text-right">
                <v-text-field
                  v-model="client_refer"
                  outlined
                  placeholder="กรอกข้อมูล"
                  height="32px"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
            
            <v-row justify="space-between">
              <v-col align-self="center">ข้อมูลลูกค้า</v-col>
              <v-col class="text-right">
                <v-btn
                  small
                  color="primary"
                  depressed
                  @click="showCustomerDialog = true"
                >นำเข้าข้อมูลลูกค้า
                </v-btn>
              </v-col>
            </v-row>
            
            <v-row justify="space-between">
              <v-col align-self="center">ชื่อลูกค้า</v-col>
              <v-col class="text-right">
                <v-text-field
                  v-model="customer_name"
                  :rules="[rules.required]"
                  outlined
                  placeholder="กรอกข้อมูล"
                  height="32px"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
            
            <v-row justify="space-between">
              <v-col align-self="center">เบอร์โทร</v-col>
              <v-col class="text-right">
                <v-text-field
                  :disabled="disabled_tel"
                  :rules="[rules.required]"
                  v-model="customer_tel"
                  type="number"
                  outlined
                  placeholder="กรอกข้อมูล"
                  height="32px"
                  hide-details="auto"
                  @blur="checkMobileDuplicate(customer_tel, customer_id)"
                />
              </v-col>
            </v-row>
            
            <v-row justify="space-between" class="mt-0">
              <v-col align-self="center">เลือกประเภทการจัดส่ง</v-col>
              <v-col class="text-right">
                <v-select
                  v-model="deliveryType"
                  class="rounded mt-3"
                  :items="deliveryTypeSelect"
                  outlined
                  dense
                  height="32px"
                  hide-details="auto"
                  placeholder="เลือกประเภทการจัดส่ง"
                ></v-select>
              </v-col>
            </v-row>
            
            <div v-if="deliveryType !== 'ลูกค้ามารับ'">
              <v-row justify="space-between" class="mt-2">
                <v-col>ที่อยู่จัดส่ง</v-col>
                <v-col class="text-right">
                  <v-textarea
                    :rules="[rules.required]"
                    placeholder="รายละเอียดที่อยู่จัดส่ง"
                    hide-details="auto"
                    rows="2"
                    outlined
                    v-model="customer_address"
                  ></v-textarea>
                </v-col>
              </v-row>
              
              <v-row
                justify="space-between"
                class="my-2"
                style="position: relative"
              >
                <v-col align-self="center">ตำบล</v-col>
                <v-col class="text-right">
                  <!--              <v-text-field outlined placeholder="กรอกข้อมูล" height="32px" hide-details/>-->
                  <thai-address-input
                    type="subdistrict"
                    v-model="searchAddress"
                    input-class="form-control"
                    @selected="onSearched"
                  >
                  </thai-address-input>
                </v-col>
              </v-row>
              
              <v-row v-if="searchResult" class="mb-2 mx-0">
                <p>
                  {{
                    searchResult.province === "กรุงเทพมหานคร" ? "แขวง" : "ตำบล"
                  }}{{ searchResult.subdistrict }}
                  {{
                    searchResult.province === "กรุงเทพมหานคร" ? "เขต" : "อำเภอ"
                  }}{{ searchResult.district }}
                  {{
                    searchResult.province === "กรุงเทพมหานคร"
                      ? "กรุงเทพมหานคร"
                      : `จังหวัด${searchResult.province}`
                  }}
                  {{ searchResult.postalCode }}
                </p>
              </v-row>
              
              <v-row v-if="addressFromSelect" class="mb-2 mx-0">
                <p>
                  {{
                    addressFromSelect.province === "กรุงเทพมหานคร"
                      ? "แขวง"
                      : "ตำบล"
                  }}{{ addressFromSelect.subdistrict }}
                  {{
                    addressFromSelect.province === "กรุงเทพมหานคร"
                      ? "เขต"
                      : "อำเภอ"
                  }}{{ addressFromSelect.district }}
                  {{
                    addressFromSelect.province === "กรุงเทพมหานคร"
                      ? "กรุงเทพมหานคร"
                      : `จังหวัด${addressFromSelect.province}`
                  }}
                  {{ addressFromSelect.postalCode }}
                </p>
              </v-row>
              
              <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-header class="px-0">
                    เลือกจุดหมายบนแผนที่
                    <template v-slot:actions>
                      <v-icon color="primary"> $expand</v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="pb-0 px-0">
                    <Map
                      @getDistance="getDistance"
                      :view-mode="viewModeMap"
                      :lat-lng="latlngSelect"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              
              <v-row justify="space-between" class="mt-4">
                <v-col align-self="center">ระยะทาง</v-col>
                <v-col class="text-right">
                  <v-text-field
                    v-model="distance"
                    suffix="กม."
                    outlined
                    placeholder="กรอกข้อมูล"
                    height="32px"
                    type="number"
                    hide-details
                  />
                </v-col>
              </v-row>
              
              <!--              <v-row justify="space-between" class="mt-2">-->
              <!--                <v-col>Google Map Link</v-col>-->
              <!--                <v-col class="text-right">-->
              <!--                  <v-textarea-->
              <!--                      placeholder="ลิ้งจาก Google Map"-->
              <!--                      hide-details-->
              <!--                      rows="2"-->
              <!--                      outlined-->
              <!--                      v-model="linkGoogleMap"-->
              <!--                  ></v-textarea>-->
              <!--                </v-col>-->
              <!--              </v-row>-->
            </div>
            
            <v-row justify="space-between" class="mt-4">
              <v-col align-self="center">เลือกคนตัก</v-col>
              <v-col class="text-right">
                <v-select
                  outlined
                  dense
                  height="32px"
                  class="rounded"
                  v-model="scooper_id"
                  :items="scoopSelect"
                  item-text="first_name"
                  item-value="id"
                  hide-details="auto"
                  placeholder="เลือกคนตัก"
                  select
                  clearable
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.first_name }} {{ data.item.last_name }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.first_name }} {{ data.item.last_name }}
                  </template>
                </v-select>
                <!--              <v-select-->
                <!--                  class="rounded mt-3"-->
                <!--                  :items="scoopSelect"-->
                <!--                  outlined-->
                <!--                  dense-->
                <!--                  height="32px"-->
                <!--                  hide-details-->
                <!--                  placeholder="เลือกคนตัก"-->
                <!--              ></v-select>-->
              </v-col>
            </v-row>
            
            <p style="color: gray; font-size: 0.85rem; padding-top: 4px">
              **ถ้ารายการสินค้าที่อยู่ในออเดอร์ไม่มีการคิดค่าตัก
              ระบบจะไม่คิดค่าตักของคนตักที่เลือก**
            </p>
            
            <v-row justify="space-between" class="mt-4">
              <v-col align-self="center">เลือกเซลล์</v-col>
              <v-col class="text-right">
                <v-select
                  clearable
                  outlined
                  dense
                  height="32px"
                  class="rounded"
                  v-model="seller_id"
                  :items="sellerOptions"
                  item-value="id"
                  hide-details="auto"
                  placeholder="เลือกเซลล์"
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.first_name }} {{ data.item.last_name }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.first_name }} {{ data.item.last_name }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
            
            <div v-if="deliveryType === 'ออกไปส่ง'">
              <v-row class="mt-4">
                <v-col align-self="center">
                  <span class="mr-2">เที่ยวรถ</span>
                  <v-icon @click="addTrip" color="success"
                  >mdi-plus-circle
                  </v-icon
                  >
                </v-col>
              </v-row>
              
              <v-data-table
                :items-per-page="-1"
                :items="trips"
                :headers="tripHeader"
                :header-props="{ sortIcon: null }"
                hide-default-footer
              >
                <template #item.no="{ item }">
                  <td>
                    {{ trips.indexOf(item) + 1 }}
                  </td>
                </template>
                <template v-slot:item.driver_id="{ item }">
                  <v-select
                    outlined
                    dense
                    height="32px"
                    class="rounded"
                    v-model="item.driver_id"
                    :items="driverSelect"
                    item-text="first_name"
                    item-value="id"
                    :rules="[rules.required_select]"
                    hide-details="auto"
                    placeholder="เลือกคนขับรถ"
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.first_name }} {{ data.item.last_name }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.first_name }} {{ data.item.last_name }}
                    </template>
                  </v-select>
                </template>
                
                <template v-slot:item.car_license="{ item }">
                  <v-select
                    outlined
                    dense
                    height="32px"
                    class="rounded"
                    v-model="item.vehicle_id"
                    :items="vehicleSelect"
                    item-text="name"
                    item-value="id"
                    :rules="[rules.required_select]"
                    hide-details="auto"
                    placeholder="เลือกรถที่ไปรับของ"
                  >
                    <template slot="selection" slot-scope="data">
                      <div
                        style="
                          display: flex;
                          width: 100%;
                          justify-content: space-between;
                        "
                      >
                        <span>{{ data.item.name }}</span>
                        <span
                        ><span style="color: #265a88"
                        >ทะเบียน {{ data.item.licence }}</span
                        ></span
                        >
                      </div>
                    </template>
                    <template slot="item" slot-scope="data">
                      <div
                        style="
                          display: flex;
                          width: 100%;
                          justify-content: space-between;
                        "
                      >
                        <span>{{ data.item.name }}</span>
                        <span
                        ><span style="color: #265a88"
                        >ทะเบียน {{ data.item.licence }}</span
                        ></span
                        >
                      </div>
                    </template>
                  </v-select>
                </template>
                
                <template v-slot:item.is_night="{ item }">
                  <v-checkbox v-model="item.is_night"/>
                </template>
                
                <template v-slot:item.trail="{ item }">
                  <v-checkbox v-model="item.trail"/>
                </template>
              </v-data-table>
            </div>
          </v-form>
        </pane>
        <pane min-size="20" style="overflow: auto">
          <TableSelectProduct
            v-model="orderItems"
            :for-create="true"
            :delivery-type="deliveryType"
            :seller-id="seller_id"
            :discount.sync="discount"
            :update-order-props="{
              id: this.$route.params.id,
              payment_method: payment_method,
              payment_status: paymentStatus,
              order_status: orderStatus,
              delivery_date: receive_date,
              hasVat: orderItems.hasVat,
            }"
            :count-order="trips.length"
          />
          
          <v-form ref="form2" v-model="valid2" lazy-validation>
            <v-row justify="space-between" class="mt-4 mx-0">
              <v-col align-self="center">การชำระเงิน</v-col>
              <v-col class="text-right">
                <v-select
                  :rules="[rules.required_select]"
                  v-model="payment_method"
                  class="rounded mt-3"
                  :items="paymentSelect"
                  outlined
                  dense
                  height="32px"
                  hide-details="auto"
                  placeholder="เลือกรูปแบบการชำระเงิน"
                ></v-select>
              </v-col>
            </v-row>
            
            <!--          {{prepaidAmount}}-->
            
            <!--          {{paymentSelect}}-->
            
            <v-row justify="space-between" class="mt-4 mx-0">
              <v-col align-self="center">เครดิตลูกค้า</v-col>
              <v-col class="text-right"> {{ creditLeft || 0 }} วัน</v-col>
            </v-row>
            
            <v-row
              v-if="payment_method === 'ตัดเงินเติม'"
              justify="start"
              class="mt-4 mx-0"
            >
              <v-col align-self="center">เงินคงเหลือ</v-col>
              <v-col class="text-right">
                <span
                  v-if="
                    (sumTotal * (orderItems.hasVat ? 1.07 : 1)).toFixed(2) >
                    prepaidAmount
                  "
                  class="red--text"
                >(เงินคงเหลือไม่พอ ยอดเงินเหลือจะติดลบหากตัดเงิน)</span
                >
                {{ prepaidAmount }} บาท
              </v-col>
            </v-row>
            
            <v-row justify="space-between" class="mt-4 mx-0">
              <v-col align-self="center">วันที่รับสินค้า</v-col>
              <v-col class="text-right">
                <DatePicker
                  v-model="receive_date"
                  placeholder="เลือกวันที่"
                  :min="new Date().toISOString().substr(0, 10)"
                  hide-details="auto"
                  default-today
                />
              </v-col>
            </v-row>
            
            <v-row justify="space-between" class="mt-4 mx-0">
              <v-col align-self="center">สถานะการชำระเงิน</v-col>
              <v-col class="text-right">
                <span
                  v-if="
                    payment_method === 'ตัดเงินเติม' &&
                    (sumTotal * (orderItems.hasVat ? 1.07 : 1)).toFixed(2) <=
                      prepaidAmount
                  "
                  class="green--text"
                >ชำระเงินแล้ว</span
                >
                <span v-else
                >{{ paymentStatus }}
                  <a style="font-size: 0.75rem">เปลี่ยนเป็นชำระเงิน</a></span
                >
              </v-col>
            </v-row>
            
            <v-row justify="space-between" class="mt-4 mx-0">
              <v-col align-self="start"> หมายเหตุ</v-col>
              <v-col>
                <v-textarea
                  rows="2"
                  hide-details
                  outlined
                  v-model="remark"
                ></v-textarea>
              </v-col>
            </v-row>
            
            <v-row justify="space-between" class="mt-4 mx-0">
              <v-col align-self="center">สถานะของออเดอร์</v-col>
            </v-row>
            
            <OrderStatusTimeline v-model="orderStatus" :for-create="true"/>
          </v-form>
          
          <v-row justify="start" class="my-4 mx-4">
            <v-btn
              :disabled="!valid || !valid2"
              :loading="isLoading"
              @click="createOrder"
              depressed
              color="success"
              dark
              class="mr-4"
            >
              <v-icon left>mdi-content-save</v-icon>
              บันทึก
            </v-btn>
          </v-row>
        </pane>
      </splitpanes>
    </v-card>
    
    <v-dialog v-model="showCustomerDialog" max-width="526">
      <v-stepper v-model="customerStep">
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card max-height="90%" elevation="0">
              <div>
                <v-text-field auto-focus prepend-inner-icon="mdi-magnify"
                      v-model="customerSearch"
                      hide-details
                      filled
                      placeholder="พิมพ์ค้นหาชื่อลูกค้า"
                      class="mt-1"
                      @input="getCustomer()"
                      height="48px" clearable
                ></v-text-field>
              </div>
              <p v-if="customer_loading" class="text-center my-4">กำลังค้นหาลูกค้า....</p>
              <p v-else-if="customerSearch.length > 1 && customers.length === 0" class="text-center my-4">ไม่พบชื่อลูกค้า</p>
              <v-list v-else flat>
                <v-list-item
                  class="px-0"
                  v-for="(item, i) in customers"
                  :key="i"
                >
                  <v-list-item-content
                    @click="
                      addressSelect = customers[i];
                      getSelectCustomer(addressSelect, 1, false);
                    "
                  >
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                    <v-list-item-subtitle>เบอร์ลูกค้า {{ item.tel || '-' }}</v-list-item-subtitle>
                    <v-list-item-subtitle class="error--text" v-if="item.is_over_overdue">
                      ยอดที่กำหนด: {{ item.is_over_overdue.max_purchase_amount }} บาท / ยอดค้างชำระ: {{ item.is_over_overdue.total_price__sum }} บาท
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  
                  <v-list-item-action>
                    <v-btn :disabled="!!item.is_over_overdue"
                      @click="
                        addressSelect = customers[i];
                        customerStep = 2;
                      "
                      depressed
                    >
                      ที่อยู่ลูกค้า
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
        
        <v-stepper-items>
          <v-stepper-content step="2">
            <v-card>
              <div style="font-size: 1.45rem">
                <v-row>
<!--                  <v-col style="font-size: 1.45rem">-->
<!--                    <v-icon @click="customerStep = 1">mdi-arrow-left</v-icon>-->
<!--                    ที่อยู่ของลูกค้า-->
<!--                  </v-col>-->
                  <v-col>
                    <v-text-field
                      v-model="customerAddressSearch"
                      hide-details
                      outlined
                      placeholder="ค้นหาที่อยู่ลูกค้า"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-list flat>
                <v-list-item
                  class="px-0"
                  v-for="(item, i) in filteredCustomerAddress"
                  :key="i"
                >
                  <v-list-item-content
                    @click="getSelectCustomer(addressSelect, i, true)"
                  >
                    <v-list-item-title>
                      {{ item.address }} {{ item.district }} {{ item.county }}
                      {{ item.province }} {{ item.code }}
                    </v-list-item-title>
                    <v-list-item-subtitle>เบอร์ที่อยู่ {{ item.tel || '-' }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-subheader class="justify-center" v-if="!filteredCustomerAddress.length">
                  ไม่มีที่อยู่บันทึกไว้
                </v-subheader>
              </v-list>
              
              <v-card-actions class="px-0">
                <v-btn class="px-0" text @click="customerStep = 1">
                  ย้อนกลับ
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Map from "@/components/Map";
import TableSelectProduct from "@/components/TableSelectProduct";
import DatePicker from "@/components/DatePicker";
import OrderStatusTimeline from "@/components/OrderStatusTimeline";
import _ from "lodash";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";

export default {
  components: {
    Map,
    TableSelectProduct,
    DatePicker,
    OrderStatusTimeline,
    Splitpanes,
    Pane,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "ต้องกรอก",
        required_select: (value) => !!value || "ต้องเลือก",
        min: (value) => value > 0 || "จำนวนต้องเป็นตั้งแต่ 1 ขึ้นไป",
      },
      valid: true,
      valid2: true,
      customersData: [{}],
      showCustomerDialog: false,
      customerStep: 1,
      customerIndex: 0,
      addressSelect: {},
      addressIndex: 0,
      customer_id: null,
      customer_name: "",
      customer_tel: "",
      customer_address: "",
      deliveryType: "ออกไปส่ง",
      deliveryTypeSelect: [
        {
          text: "ออกไปส่ง",
          value: "ออกไปส่ง",
        },
        {
          text: "จ้างรถข้างนอก",
          value: "จ้างรถข้างนอก",
        },
        {
          text: "ลูกค้ามารับ",
          value: "ลูกค้ามารับ",
        },
      ],
      scooper_id: null,
      scoopSelect: [
        {
          text: "ออกไปส่ง",
          value: "ออกไปส่ง",
        },
      ],
      trips: [{ driver_id: "", car_license: "", is_night: false }],
      paymentSelect: [
        {
          text: "เงินสด",
          value: "เงินสด",
        },
        {
          text: "โอนเงิน",
          value: "โอนเงิน",
        },
        {
          text: "บัตรเครดิต",
          value: "บัตรเครดิต",
        },
        {
          text: "ชำระภายหลัง",
          value: "ชำระภายหลัง",
        },
      ],
      client_refer: "",
      payment_method: "",
      driverSelect: [],
      vehicleSelect: [],
      remark: "",
      distance: 0,
      searchAddress: "",
      searchResult: null,
      receive_date: "",
      paymentStatus: "ยังไม่ชำระ",
      orderStatus: "รับออเดอร์",
      orderItems: {
        item: [],
        delivery: {
          price: 0,
          amount: 1,
          total_price: 0,
          discount: 0,
          commission_rate: 0,
        },
        hasVat: false,
        netPrice: 0,
      },
      delivery_rate: [],
      tripHeader: [
        {
          text: "เที่ยว",
          value: "no",
        },
        {
          text: "คนขับ",
          value: "driver_id",
        },
        {
          text: "รถ",
          value: "car_license",
        },
        {
          text: "กลางคืน",
          value: "is_night",
        },
        {
          text: "พ่วง",
          value: "trail",
        },
      ],
      deliveryPrice: 0,
      prepaidAmount: 0,
      creditLeft: 0,
      address_id: null,
      latlngSelect: {
        lat: "",
        long: "",
      },
      viewModeMap: false,
      seller_id: null,
      sellerOptions: [],
      addressFromSelect: null,
      discount: 0,
      isLoading: false,
      linkGoogleMap: "",
      customerSearch: "",
      customerAddressSearch: "",
      disabled_tel: false,
      customers:[],
      customer_loading:false
    };
  },
  async created() {
    this.getDeliveryRate();
    this.getVehicle();
    this.getDriver();
    this.getCustomer();
    this.getScoop();
    this.getSeller();
  },
  methods: {
    getSeller() {
      axios.get("/api/stocks/seller/").then((res) => {
        this.sellerOptions = res.data.data;
      });
    },
    getDeliveryRate() {
      axios
        .get("/api/orders/delivery_rate/")
        .then((res) => {
          this.delivery_rate = res.data.data;
        })
        .catch((e) => {
          this.$store.state.snackbar = {
            text:
              "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : " +
              e.response.status +
              " " +
              e.response.statusText,
            visible: true,
            color: "error",
          };
        });
    },
    getCustomer() {
      if (this.customerSearch.length > 1) {
        this.customer_loading = true
        axios
        .get(`api/accounts/customer/?name=${this.customerSearch}`)
        .then((res) => {
          this.customers = res.data.data;
        })
        .catch((e) => {
          this.$store.state.snackbar = {
            text:
              "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : " +
              e.response.status +
              " " +
              e.response.statusText,
            visible: true,
            color: "error",
          };
        }).finally(()=>this.customer_loading = false)
      }
    },
    getDriver() {
      axios
        .get("/api/stocks/driver/")
        .then((res) => {
          this.driverSelect = res.data.data;
        })
        .catch((e) => {
          this.$store.state.snackbar = {
            text:
              "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : " +
              e.response.status +
              " " +
              e.response.statusText,
            visible: true,
            color: "error",
          };
        });
    },
    getVehicle() {
      axios
        .get("/api/stocks/vehicle/รถบรรทุก/")
        .then((res) => {
          this.vehicleSelect = res.data.data;
        })
        .catch((e) => {
          this.$store.state.snackbar = {
            text:
              "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : " +
              e.response.status +
              " " +
              e.response.statusText,
            visible: true,
            color: "error",
          };
        });
    },
    getScoop() {
      axios
        .get("/api/stocks/driver/")
        .then((res) => {
          this.scoopSelect = res.data.data;
          this.scooper_id = res.data.data[0].id;
          console.log(res.data.data);
        })
        .catch((e) => {
          this.$store.state.snackbar = {
            text:
              "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : " +
              e.response.status +
              " " +
              e.response.statusText,
            visible: true,
            color: "error",
          };
        });
    },
    onSearched(address) {
      this.searchAddress = address.subdistrict;
      this.searchResult = address;
    },
    getDistance(value) {
      this.distance = value.distance;
      this.latlngSelect = value.latlng;
      // this.delivery_rate.forEach(d=>{
      //   if(this.distance > d.distance){
      //     console.log('than')
      //   }
      // })
    },
    calDeliveryPrice(value) {
      let rate = [];
      this.delivery_rate.forEach((r) => {
        rate.push(r);
      });
      rate.push({ distance: parseFloat(value) || 0, price: "x" });
      rate = _.orderBy(rate, "distance");
      let index = rate.findIndex((x) => x.price === "x");
      return rate[index - 1].price;
    },
    addTrip() {
      this.trips.push({
        driver_id: "",
        car_license: "",
        is_night: false,
        trail: false,
      });
    },
    removeTrip(index) {
      if (this.trips.length > 1) {
        this.trips.splice(index, 1);
      }
    },
    getSelectCustomer(customer, index, includeAddress) {
      if (customer.is_over_overdue) {
        this.$store.state.snackbar = {
          text: 'ยอดค้างชำระเกินที่กำหนด',
          visible: true,
          color: 'error'
        }
        return
      }
      this.disabled_tel = true
      this.customer_name = customer.name
      this.customer_tel = customer.tel
      this.customer_id = customer.id
      if (includeAddress) {
        this.customer_address = customer.customer_address[index].address || "";
        this.searchAddress = customer.customer_address[index].district || "";
        this.addressFromSelect = {
          subdistrict: customer.customer_address[index].district,
          district: customer.customer_address[index].county,
          province: customer.customer_address[index].province,
          postalCode: customer.customer_address[index].code,
        };
        // this.searchResult.subdistrict = customer.customer_address[index].district || ''
        this.distance = customer.customer_address[index].distance || 0;
        this.address_id = customer.customer_address[index].id;
        if (customer.customer_address[index].latitude) {
          this.latlngSelect = {
            lat: parseFloat(customer.customer_address[index].latitude),
            lng: parseFloat(customer.customer_address[index].longitude),
          };
          this.viewModeMap = true;
        }
        // this.linkGoogleMap = customer.customer_address[index].latitude
      } else {
        this.customer_address = "";
        this.searchAddress = "";
        this.distance = 0;
        this.address_id = null;
        this.latlngSelect = { lat: null, lng: null };
        this.viewModeMap = false;
      }
      this.creditLeft = customer.credit;
      if (parseFloat(customer.prepaid)) {
        this.paymentSelect.push({
          text: "ตัดเงินเติม",
          value: "ตัดเงินเติม",
        });
        this.prepaidAmount = parseFloat(customer.prepaid);
      }
      this.showCustomerDialog = false;
    },
    async createOrder() {
      await this.$refs.form.validate();
      await this.$refs.form2.validate();
      if (this.valid && this.valid2 && this.isLoading === false) {
        this.isLoading = true
        let payload = {
          customer_name: this.customer_name,
          tel: this.customer_tel,
          is_tax_address: true,
          address_id: this.address_id,
          distance: parseFloat(this.distance).toFixed(2),
          scooper_id: this.scooper_id,
          seller_id: this.seller_id,
          delivery_type: this.deliveryType,
          payment_method: this.payment_method,
          payment_status: this.paymentStatus,
          order_status: this.orderStatus,
          delivery_date: this.receive_date,
          hasVat: this.orderItems.hasVat,
          trip: [],
          order_item: [],
          remark: this.remark,
          client_refer: this.client_refer,
        };
        if (
          this.deliveryType === "ออกไปส่ง" ||
          this.deliveryType === "จ้างรถข้างนอก"
        ) {
          payload.address = this.customer_address;
          if (this.searchResult !== null || this.addressFromSelect !== null) {
            payload.district = this.addressFromSelect
              ? this.addressFromSelect.subdistrict
              : this.searchResult.subdistrict;
            payload.county = this.addressFromSelect
              ? this.addressFromSelect.district
              : this.searchResult.district;
            payload.province = this.addressFromSelect
              ? this.addressFromSelect.province
              : this.searchResult.province;
            payload.code = this.addressFromSelect
              ? this.addressFromSelect.postalCode
              : this.searchResult.postalCode;
            payload.lat = this.latlngSelect.lat;
            payload.long = this.latlngSelect.lng;
          } else {
            payload.district = "";
            payload.county = "";
            payload.province = "";
            payload.code = "";
          }
          // // payload.lat = this.latlngSelect.lat
          // payload.lat = this.linkGoogleMap
          // payload.long = ''
        }
        
        // let discounts = []
        
        this.orderItems.item.forEach((i) => {
          // if (i.category === 'discount') {
          //   discounts.push(i)
          // } else
          if (i.category === "product" || i.category === "gift") {
            payload.order_item.push({
              product_id: i.product_id,
              quantity: parseFloat(i.item_quantity),
              discount: parseFloat(i.discount || "0"),
              price_per_unit: parseFloat(i.item_price_per_unit || "0"),
            });
          } else if (i.category === "other") {
            payload.order_item.push({
              product_id: null,
              name: i.item_name,
              quantity: parseFloat(i.item_quantity),
              unit: "รายการ",
              discount: parseFloat(i.discount),
              total_price: i.item_total - parseFloat(i.discount),
              commission_rate: parseFloat(i.commission_rate) || 0,
              cost: parseFloat(i.cost) || 0,
              commission_price:
                ((i.item_total - parseFloat(i.cost) - parseFloat(i.discount)) *
                  (parseFloat(i.commission_rate) / 100)).toFixed(2) || 0,
            });
          }
        });
        
        // console.log('deli',this.orderItems.delivery)
        
        // let totalDelivery = 0
        //
        // if(this.deliveryType ==='จ้างรถจากข้างนอก'){
        //   totalDelivery = this.orderItems.delivery.total_price
        // } else {
        //   totalDelivery = this.orderItems.delivery.price * this.orderItems.delivery.amount
        // }
        
        let delivery_total_price =
          this.deliveryType === "จ้างรถข้างนอก"
            ? parseFloat(this.orderItems.delivery.total_price)
            : parseFloat(this.orderItems.delivery.price) *
            parseInt(this.orderItems.delivery.amount);
        
        // console.log(discounts.reduce((a, b) => a + (parseFloat(b['item_total']) || 0), 0))
        
        // payload.discount = discounts.reduce((a, b) => a + (parseFloat(b['item_total']) || 0), 0)
        payload.discount = this.discount;
        
        if (this.deliveryType === "ออกไปส่ง") {
          // console.log(this.trips)
          this.trips.forEach((t) => {
            payload.trip.push({
              driver_id: t.driver_id,
              vehicle_id: t.vehicle_id,
              at_night: t.is_night,
              trail: t.trail,
            });
          });
        }

        // payload.order_item.push({
        //   product_id: null,
        //   name: "ค่าส่ง",
        //   quantity: this.orderItems.delivery.amount,
        //   unit: "ครั้ง",
        //   discount: parseFloat(this.orderItems.delivery.discount),
        //   total_price: delivery_total_price,
        //   commission_rate: parseFloat(this.orderItems.delivery.commission_rate),
        //   commission_price:
        //     (delivery_total_price *
        //       (parseFloat(this.orderItems.delivery.commission_rate) / 100)).toFixed(2),
        // });

        console.log(payload);
        
        axios
          .post("/api/orders/", payload)
          .then((res) => {
            console.log(res);
            this.isLoading = false;
            this.$store.state.snackbar = {
              text: "เปิดออเดอร์สำเร็จ",
              visible: true,
              color: "success",
            };
            this.$router.push("/order");
          })
          .catch((e) => {
            this.$store.state.snackbar = {
              text:
                "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : " +
                e.response.status +
                " " +
                JSON.stringify(e.response.data),
              visible: true,
              color: "error",
            };
            this.isLoading = false;
          });
        
        // this.orderItems.item.forEach(i=>{
        //   if(i.category !== 'discount'){
        //
        //   }
        // })
      }
    },
    async checkMobileDuplicate (mobile, customer_id) {
      if (!mobile) return
      try {
        await axios.get(`/api/accounts/check-mobile-duplicate/${mobile}/customer/${customer_id}/`)
      } catch (e) {
        this.valid = false
        if (e.response.status === 400) {
          this.$store.state.snackbar = {
              text: e.response.data,
              visible: true,
              color: "error",
            };
        } else {
          this.$store.state.snackbar = {
              text:
                "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : " +
                e.response.status +
                " " +
                JSON.stringify(e.response.message),
              visible: true,
              color: "error",
            };
        }
      }
    }
  },
  computed: {
    sumTotal() {
      let sum = this.orderItems.item.reduce((a, b) => a + (b["total"] || 0), 0);
      let total_price_trip =
        this.deliveryType === "จ้างรถข้างนอก"
          ? this.orderItems.delivery.total_price
          : this.orderItems.delivery.price * this.orderItems.delivery.amount;
      let shipping_price = total_price_trip - this.orderItems.delivery.discount;
      return sum + shipping_price;
    },
    // filteredCustomer() {
    //   if (this.customerSearch) {
    //     return this.customersData.filter((c) =>
    //       c.name.toLowerCase().includes(this.customerSearch.toLowerCase())
    //     );
    //   } else {
    //     return this.customersData;
    //   }
    // },
    // getCustomer() {
    //   axios.get('/api/accounts/customer/')
    //       .then(res => {
    //         this.customers = res.data.data
    //       })
    //       .catch(e => {
    //         this.$store.state.snackbar = {
    //           text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
    //           visible: true,
    //           color: 'error'
    //         }
    //       }).finally(() => {
    //     this.$store.state.isLoading = false
    //   })
    // },
    filteredCustomerAddress() {
      if (this.addressSelect.customer_address) {
        return this.addressSelect.customer_address.filter((c) =>
          (c.address + c.district + c.province + c.county + c.code)
            .toLowerCase()
            .includes(this.customerAddressSearch.toLowerCase())
        );
      }
      return [];
    },
  },
  watch: {
    distance(value) {
      this.orderItems.delivery.price = this.calDeliveryPrice(value);
      if (this.deliveryType === "ออกไปส่ง") {
        this.orderItems.delivery.amount = this.trips.length;
        // this.orderItems.delivery.total_price = this.trips.length * this.orderItems.delivery.price````````````
      } else if (this.deliveryType === "ลูกค้ามารับ") {
        this.orderItems.delivery.price = 0;
        this.orderItems.delivery.amount = 1;
        this.orderItems.delivery.total_price = 0;
        this.orderItems.delivery.discount = 0;
      }
    },
    trips: {
      deep: true,
      
      // We have to move our method to a handler field
      handler() {
        console.log(this.trips.length * this.orderItems.delivery.price);
        if (this.deliveryType === "ออกไปส่ง") {
          this.orderItems.delivery.amount = this.trips.length;
          // this.orderItems.delivery.total_price = this.trips.length * this.orderItems.delivery.price
        }
      },
    },
  },
  // mounted() {
  //   window.onpopstate = function(event) {
  //     alert("location: " + document.location + ", state: " + JSON.stringify(event.state));
  //   };
  // }
};
</script>

<style scoped>
.main-container {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-flow: column;
}

.box {
  display: flex;
  flex-direction: column;
}

.box-1 {
  height: 60px;
}

.box-2 {
  flex: 1;
}

.v-toolbar {
  flex: unset;
}
</style>

<style>
.form-control {
  padding: 3px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  width: 100%;
}

.suggestion-list {
  text-align: left;
  padding: 4px;
  position: absolute;
  background-color: white;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  border-radius: 4px;
}

.suggestion-list-item:hover {
  cursor: pointer;
  background-color: #c7ddef;
}

.suggestion-list {
  max-height: 100px;
  overflow: auto;
}
</style>

<style scoped>
.splitpanes >>> .splitpanes__splitter {
  position: relative;
  width: 5px;
  min-width: 5px;
  background-color: #d3d3d3;
}

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
